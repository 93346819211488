<template>
  <div class="detail-dialog" v-show="showDetail">
    <div class="dialog-main">
      <div class="close-btn" @click="closeDialog">关闭 X</div>
      <div class="info-main">
        <div class="info-title">{{ projectInfo.bidName }}</div>
        <div class="info-time">{{ projectInfo.updateTime }}</div>
        <div class="info-content"  style="text-align: justify;">
          <div v-html="projectInfo.tenderContent" style="width: 100%;height: 60vh;overflow: auto;"></div>
        </div>
      </div>
      <div class="prv-next">
        <div class="prv-text" @click="goNext(preNextData.beforeId)">
          <span>上一篇：</span><span class="other-news">{{preNextData.beforeTitle?preNextData.beforeTitle:'没有了'}}</span>
        </div>
        <div class="next-text" @click="goNext(preNextData.afterId)">
          <span>下一篇：</span><span class="other-news">{{preNextData.afterTitle?preNextData.afterTitle:'没有了'}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProCureDetail,getProCureBeforeAndAfter} from "../../js/api/index";
export default {
  name: "Buydetail",
  data() {
    return {
      projectInfo:'',
      preNextData:'',
    };
  },
  props: {
    detailID: {
      type: Number,
      default: 1
    },
    showDetail:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    detailID(val){
      this.initDetailInfo(val)
    }
  },
  methods: {
    initDetailInfo(id){
      getProCureDetail(id).then(res=>{
        this.projectInfo = res.data;
      })
      getProCureBeforeAndAfter(id).then(res=>{
        if(!res.data){
          this.preNextData = {
            beforeTitle:null,
            afterTitle:null,
            beforeId:null,
            afterId:null
          }
        }else{
          this.preNextData = res.data;
        }
      })
    },
    goNext(id){
      if(!id) return;
      this.initDetailInfo(id);
    },
    closeDialog(){
      this.$emit('closeDialog',false)
    }
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="scss">
 .detail-dialog{
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 0 20vw;
  background: rgba(0,0,0,0.67);
  top: 0;
  display: flex;
  align-items: center;
  >div{
    position: relative;
    background: #fff;
    width: 52vw;
    height: 80vh;
    padding: 4vh 4vw;
    .info-main{
      .info-title{
        font-size: 1.8vw;
        font-weight: bold;
        color: #333333;
        padding-bottom: 2vh;
        margin-top: 2vh;
        border-bottom: 1px solid #DCDCDC;
      }
      .info-time{
        font-size: .9vw;
        color: #333333;
        margin: 2vh 0;
      }
    }
  }
  .prv-next{
    text-align: left;
    height: 8vh;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .other-news{
      cursor: pointer;
      transition: all ease .2s;
    }
    .other-news:hover{
      color: #004DA0;
    }
  }
  .close-btn{
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 1vh;
    font-size: .8vw;
    font-weight: 500;
    color: #333333;
  }
}
</style>
